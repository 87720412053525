import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Why I love my Corvette C8`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e6ce0a6fe6d31298863e35ae9c3f6fd6/eea4a/corvette_interior.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAYEt5bWogv8A/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECAwQREyL/2gAIAQEAAQUCdk5v00p6MrGjWLZ0P//EABcRAAMBAAAAAAAAAAAAAAAAAAABEQL/2gAIAQMBAT8Bwqyo/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BZ//EABsQAAIDAAMAAAAAAAAAAAAAAAACAREhMUFx/9oACAEBAAY/AtaW9OjULWeS7Ng//8QAHBABAQEAAQUAAAAAAAAAAAAAAREAQSExUWGR/9oACAEBAAE/IXhI8ofN1Fs4DAIt9aI2cMBRKYrsLv/aAAwDAQACAAMAAAAQWO//xAAZEQACAwEAAAAAAAAAAAAAAAAAAREhYbH/2gAIAQMBAT8QRYpM+H//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EGiwjP/EABoQAQADAQEBAAAAAAAAAAAAAAEAESExgZH/2gAIAQEAAT8QxQBMXxmS0yDABT5LvA6op+jFeTBHIq1Z4diCxGXdT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "corvette interior",
            "title": "corvette interior",
            "src": "/static/e6ce0a6fe6d31298863e35ae9c3f6fd6/e5166/corvette_interior.jpg",
            "srcSet": ["/static/e6ce0a6fe6d31298863e35ae9c3f6fd6/f93b5/corvette_interior.jpg 300w", "/static/e6ce0a6fe6d31298863e35ae9c3f6fd6/b4294/corvette_interior.jpg 600w", "/static/e6ce0a6fe6d31298863e35ae9c3f6fd6/e5166/corvette_interior.jpg 1200w", "/static/e6ce0a6fe6d31298863e35ae9c3f6fd6/eea4a/corvette_interior.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The new Chevrolet Corvette C8 is one of the most thrilling cars I've ever driven. The base model is a marvel to drive, with a weighty and dynamic steering system that conveys gobs of information and obeys even the slightest input. The Magnetic Ride Control (MRC) suspension system is highly adaptive and works with the car's traction control and stabilizer to control its handling and damping. The Corvette C8 is a beautiful car to look at. It has a V8 engine, which is an excellent choice for a luxury car. The design is also a very appealing one.`}</p>
    <p>{`The Chevrolet Corvette C8 is smooth as glass, even in comfort mode. I love that I can remove the roof to maximize the trunk space, and the convertible roof is easy to remove and store in the trunk. I'm a huge fan of the sporty styling, but it is worth it for the acoustic benefits of this vehicle. Its base version has a slightly more powerful engine, and it is also much quieter than the base model.
The C8 has a naturally aspirated V8 - a rarity among automakers. The base model is equipped with a 6.2-liter V8 engine that makes 495 horsepower and 470 pound-feet of torque. The power is sent to the rear wheels via an eight-speed dual-clutch transmission.`}</p>
    <p>{`The Corvette is a joy to drive. It's a comfortable ride, and you'll enjoy driving around with it. The optional Magnetic Ride Suspension adds even more comfort. The Corvette has become a surprisingly docile car. Some reviewers have even compared it to a whisky. In short, it's an awesome sports car. It's the best daily driver. While some people may find it a bit boring, the Corvette is a great choice for a sports car.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      